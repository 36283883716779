import React, { useState, useLayoutEffect } from 'react'
import useWidth from 'src/hooks/window/useWidth'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'

import OrangeDsIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import consortiumJSON from '../../assets/data/consortium.json'

import { Section, Card } from './style'

type consortiumType = {
  icon: string;
  title: string;
  text: string;
}

const WIDTH_MD = 768

const Consortium = () => {
  const [ isMobile, setIsMobile ] = useState(true)
  const width = useWidth(300)

  useLayoutEffect(() => {
    setIsMobile(WIDTH_MD > width)
  }, [ width ])

  return (
    <Section className='py-5 d-flex align-items-center'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='font-sora fs-24 lh-30 fs-lg-40 lh-lg-50 fw-600 text-white text-md-center text-xl-left mb-4'>
              Por que o consórcio vale a pena?
            </h2>
            {
            isMobile ? (

              <DefaultCarousel
                sm={{ items: 1, partialVisibilityGutter: 20 }}
                notShowDots={true}
                notShowArrows={true}
                itemClass='px-2'
              >
                {
                  consortiumJSON.map((consortiumData: consortiumType) => (
                    <Card key={consortiumData.title}>
                      <div className='d-block mb-3'>
                        <OrangeDsIcon size='MD' color='#ff7a00' icon={consortiumData.icon} />
                      </div>
                      <h3 className='font-sora fs-20 lh-25 text-grayscale--500 fw-600 mb-3'>{consortiumData.title}</h3>
                      <p className='fs-18 lh-22 text-grayscale--500 fw-400'>{consortiumData.text}</p>
                    </Card>
                  ))
                }
              </DefaultCarousel>
            ) : (
              <div className='row'>
                {
                    consortiumJSON.map((consortiumData: consortiumType) => (
                      <div className='col-6 col-xl-4 mt-3' key={consortiumData.text}>
                        <Card>
                          <div className='d-flex align-items-center mb-3'>
                            <div className='mr-2 d-flex'>
                              <OrangeDsIcon size='MD' color='#ff7a00' icon={consortiumData.icon} />
                            </div>
                            <h3 className='font-sora fs-16 lh-20 fs-lg-20 lh-lg-25 text-grayscale--500 fw-600 mb-0'>
                              {consortiumData.title}
                            </h3>
                          </div>
                          <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 text-grayscale--500 fw-400'>{consortiumData.text}</p>
                        </Card>
                      </div>
                    ))
                  }
              </div>
            )
          }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Consortium
