import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section, Button, Link } from './style'

type UseFGTSProps = {
  setIsOpen: Function;
  isMobile: boolean;
  oneLink: string;
}

const UseFGTS = ({ setIsOpen, isMobile, oneLink }: UseFGTSProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5 d-flex align-items-end align-items-md-center'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-xl-4'>
            <h2 className='font-sora fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600 mb-3'>
              Utilizar seu FGTS 
            </h2>
            <p className='fs-14 lh-17 fs-md-16 lh-md-19 fs-lg-18 lh-lg-22 text-grayscale--500 fw-400'>
              Seu saldo do FGTS pode ser usado para completar lances no consórcio imobiliário e acelerar o sonho da cassa própria.  
            </p>
            {
            isMobile ? (
              <Link
                href={oneLink}
                title='Simular consórcio'
                target='_blank'
                rel='noreferrer'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_04',
                    section_name: 'Utilizar seu FGTS',
                    element_action: 'click button',
                    element_name: 'Simular consórcio',
                    redirect_url: 'https://intergo.app/f76718e8',
                   })
                }}
              >
                Simular consórcio
              </Link>
            ) : (
              <Button
                title='Simular consórcio'
                onClick={() => {
                  setIsOpen(true)
                  sendDatalayerEvent({
                    section: 'dobra_04',
                    section_name: 'Utilizar seu FGTS',
                    element_action: 'click button',
                    element_name: 'Simular consórcio',
                   })
                }}
              >
                Simular consórcio
              </Button>
            )
          }
          </div>
        </div>
      </div>
    </Section>
  )
}

export default UseFGTS
