import React, { useState, useLayoutEffect } from 'react'
import useDomReady from 'src/hooks/window/useDomReady'
import useWidth from 'src/hooks/window/useWidth'
import Layout from 'src/layouts/BaseLayout'
import { WIDTH_MD } from 'src/styles/breakpoints'

import pageContext from './pageContext.json'

import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'
import QRCodeConsorcio from './assets/images/qr_consorcio_home.png'

import Hero from './_section/_hero/_index'
import KnowMore from './_section/_know-more/_index'
import Consortium from './_section/_consortium/_index'
import UseFGTS from './_section/_useFGTS/_index'
import ContemplatedConsortium from './_section/_contemplated-consortium/_index'
import Blog from './_section/_blog/_index'
import Faq from './_section/_faq/_index'

const Consorcio = () => {
  const [ isMobile, setIsMobile ] = useState(true)
  const [ isOpen, setIsOpen ] = useState(false)
  const domReady = useDomReady()
  const width = useWidth(300)
  const oneLink = 'https://intergo.app/f76718e8'

  const hubModal = domReady && (
    <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
      <QrCodeModal
        isModal={isOpen}
        setIsModal={setIsOpen}
        title={`Agora <a target="_blank" href="${oneLink}" class="text-orange--extra">abra nosso </a>app e contrate seu consórcio agora`}
        instructions='Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para o app do Inter.'
        qrCode={QRCodeConsorcio}
      />
    </Modal>
  )

  useLayoutEffect(() => {
    setIsMobile(width < WIDTH_MD)
  }, [ width ])

  return (
    <Layout pageContext={pageContext}>
      {hubModal}
      <Hero setIsOpen={setIsOpen} isMobile={isMobile} oneLink={oneLink} />
      <KnowMore />
      <Consortium />
      <UseFGTS setIsOpen={setIsOpen} isMobile={isMobile} oneLink={oneLink} />
      <ContemplatedConsortium setIsOpen={setIsOpen} isMobile={isMobile} oneLink={oneLink} />
      <Blog />
      <Faq />
    </Layout>
  )
}

export default Consorcio
