import { orange } from 'src/styles/colors'
import styled from 'styled-components'

export const ContainerCarousel = styled.section`

  .react-multi-carousel-dot-list {
      
    button {
      background: ${orange.extra}
    }

    .react-multi-carousel-dot {
      &--active {
        button {
          background: ${orange.extra}
        }
      }
    }
  }
`

export const Image = styled.div`
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
