import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

import BgMobileXl from '../../assets/images/hub-consorcio-hero-xl.png'
interface IBackgroundProps {
  banner: string;
}

export const SectionCMS = styled.section<IBackgroundProps>`
  background: url(${(props: IBackgroundProps) => props.banner || BgMobileXl});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: calc(100vh - 64px);

  @media (min-width: ${breakpoints.md}) {
    background: url(${(props: IBackgroundProps) => props.banner || BgMobileXl});
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 618px;
  }

  @media (min-width: ${breakpoints.lg}) {
    background: url(${(props: IBackgroundProps) => props.banner || BgMobileXl});
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 761px;
  }

  @media (min-width: ${breakpoints.xl}) {
    background: url(${(props: IBackgroundProps) => props.banner || BgMobileXl});
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 735px;
  }

  @media (min-width: ${breakpoints.xxxl}) {
    background: url(${(props: IBackgroundProps) => props.banner || BgMobileXl});
    background-size: 100%;
    background-repeat: no-repeat;
    min-height: 735px;
  }

  .breadcrumb {
    margin-bottom: 16px;

    @media (min-width: ${breakpoints.md}) {
      margin-bottom: 27px;
    }

    span {
      color: #ffffff !important;

      a {
        color: #ffffff !important;
        font-weight: bold;
        opacity: 1 !important;
      }
    }
  }
`
export const Button = styled.button`
  width: 100%;
  height: 40px;
  background: #FF7A00;
  border-radius: 8px;
  color: #fff;
  font-weight: bold;
  margin-top: 17px;
  max-width: 497px;
  border: none;
  font-size: 14px;
  line-height: 17px;

  &:focus {
    outline: none;
  }

  @media (min-width: ${breakpoints.lg}) {
    margin-top: 33px;
  }
  @media (min-width: ${breakpoints.xl}) {
    margin-top: 27px;
  }
`

export const Link = styled.a`
  width: 100%;
  height: 40px;
  background: #FF7A00;
  border-radius: 8px;
  color: #fff;
  font-weight: bold;
  margin-top: 17px;
  max-width: 497px;
  border: none;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus, &:hover {
    outline: none;
    color: #fff;
    opacity: 0.9;
  }

  @media (min-width: ${breakpoints.lg}) {
    margin-top: 33px;
  }
  @media (min-width: ${breakpoints.xl}) {
    margin-top: 27px;
  }
`
