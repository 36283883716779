import React from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import Breadcrumb from 'src/components/Breadcrumb'
import OpenVideo from 'src/components/OpenVideo'

import playVideoOrange from 'src/assets/images/shared/play-video-orange.png'

import dataCMS from 'src/assets/data/consorcio/consorcio.json'

import { SectionCMS, Button, Link } from './style'

type heroProps = {
  setIsOpen: Function;
  isMobile: boolean;
  oneLink: string;
}

type consorcioBanner = {
  title: string;
  description: string;
  image: {
    url: string;
    alt: string;
  };
}

const Hero = ({ setIsOpen, isMobile, oneLink }: heroProps) => {
  const cmsData: consorcioBanner[] = dataCMS
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <SectionCMS banner={cmsData[0].image.url} className='py-5 d-flex align-items-end align-items-md-center'>
      <div className='container'>
        <div className='row d-flex align-items-md-center'>
          <div className='d-none'>
            <div className='col-12 col-md-6 d-flex justify-content-center order-md-last mb-5 mb-md-0'>
              <OpenVideo
                link='https://www.youtube.com/embed/ymAQwgdHLnM'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_01',
                    section_name: 'Titulo de consorcio local',
                    element_action: 'click video',
                    element_name: 'null',
                    redirect_url: 'https://www.youtube.com/embed/ymAQwgdHLnM',
                   })
                }}
              >
                <img src={playVideoOrange} alt='Assistir vídeo' className='cursor-pointer' width='85' height='129' />
              </OpenVideo>
            </div>
          </div>
          <div className='col-12 col-md-6 pt-5 pt-md-0'>
            <div className='breadcrumb'>
              <Breadcrumb
                sectionName={cmsData[0].title}
                type='pra você'
                link='/pra-voce/consorcio/'
                text='Consórcio'
                styles='text-white'
              />
            </div>
            <h1
              className='font-sora fs-24 lh-30 fs-lg-40 lh-lg-50 fs-xl-48 lh-xl-60 text-white fw-600 mb-3'
              dangerouslySetInnerHTML={{ __html: cmsData[0].title }}
            />
            <p
              className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-white fw-400'
              dangerouslySetInnerHTML={{ __html: cmsData[0].description }}
            />

            {
              isMobile ? (
                <Link
                  href={oneLink}
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_01',
                      section_name: cmsData[0].title,
                      element_action: 'click button',
                      element_name: 'Simular no Super App',
                      redirect_url: 'https://intergo.app/f76718e8',
                     })
                  }}
                >
                  Simular no Super App
                </Link>
              ) : (
                <Button
                  onClick={() => {
                    setIsOpen(true)
                    sendDatalayerEvent({
                      section: 'dobra_01',
                      section_name: cmsData[0].title,
                      element_action: 'click button',
                      element_name: 'Simular no Super App',
                     })
                  }}
                >
                  Simular no Super App
                </Button>
              )
            }
          </div>
        </div>
      </div>
    </SectionCMS>
  )
}

export default Hero
