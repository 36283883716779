import React from 'react'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import dataJSON from '../../assets/data/_know-more-data'

import { ContainerCarousel, Image } from './style'

type ConsortiumType = {
  image: string;
  title: string;
  description: string;
  link: string;
}

const KnowMore = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <ContainerCarousel className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-center justify-content-xl-between'>
          <div className='col-12 col-lg-10 col-xl-5'>
            <h2 className='font-sora fs-24 lh-30 fs-md-32 lh-md-40 fs-lg-40 lh-lg-50 text-grayscale--500 fw-600 text-center text-xl-left'>
              Conheça o Consórcio Inter
            </h2>
            <p className='fs-14 lh-17 fs-md-18 lh-md-22 fs-lg-18 lh-lg-22 text-grayscale--500 fw-400 text-center text-xl-left'>
              Aproveite as vantagens exclusivas e simule seu bem! 
            </p>
          </div>

          <div className='col-12 col-lg-9 col-xl-7'>
            <DefaultCarousel
              sm={{ items: 1 }}
              md={{ items: 1 }}
              lg={{ items: 1 }}
              xl={{ items: 1 }}
            >
              {
                dataJSON.map((ConsortiumData: ConsortiumType) => (
                  <div className='row d-flex align-items-center' key={ConsortiumData.title}>
                    <div className='col-12 col-md-5 mb-4 mb-md-0'>
                      <Image>
                        <img src={ConsortiumData.image} />
                      </Image>
                    </div>

                    <div className='col-12 col-md-7'>
                      <h3 className='font-sora fs-24 lh-30 text-grayscale--500 fw-600 mb-2'>{ConsortiumData.title}</h3>
                      <p className='fs-14 lh-17 text-grayscale--500 fw-400 mb-2'>{ConsortiumData.description}</p>
                      <a
                        className='fs-14 lh-17 text-orange--extra fw-600'
                        href={ConsortiumData.link}
                        target='_blank'
                        rel='noreferrer'
                        title='Saiba mais'
                        onClick={() => {
                          sendDatalayerEvent({
                            section: 'dobra_02',
                            section_name: 'Saiba mais sobre os consórcios Inter',
                            element_action: 'click button',
                            element_name: `Saiba mais + ${ConsortiumData.title}`,
                            redirect_url: ConsortiumData.link,
                           })
                        }}
                      >
                        Saiba mais
                      </a>
                    </div>
                  </div>
                ))
              }
            </DefaultCarousel>
          </div>
        </div>
      </div>
    </ContainerCarousel>
  )
}

export default KnowMore
