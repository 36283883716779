import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

// Images
import ImageBg from '../../assets/images/hub-consortium-bg.png'

export const Section = styled.section`
  background-color: #ff7a00;
  background-image: url(${ImageBg});
  min-height: 467px;
  /* display: flex; */
  /* flex-direction: column;
  align-items: center; */

  @media (min-width: ${breakpoints.lg}) {
    min-height: 799px;
  }
  @media (min-width: ${breakpoints.lg}) {
    min-height: 839px;
  }
  @media (min-width: ${breakpoints.xl}) {
    min-height: 652px;
}
`
export const Card = styled.div`
  background: #ffffff;
  border-radius: 16px;
  padding: 30px 16px 16px;
  height: 279px;
  width: 100%;

  @media (min-width: ${breakpoints.md}) {
    height: 160px;
  }
`
