import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const Card = styled.div`
  img {
    width: 100%;
    height: 244px;
    object-fit: cover;
  }

  .content {
    padding: 16px 24px;
    border-radius: 16px 16px 0 0;
    margin-top: -16px;
    background: #fff;
    position: relative;

    @media (min-width: ${breakpoints.md}) {
      padding: 16px 17px;
    }

    @media (min-width: ${breakpoints.lg}) {
      padding: 16px 24px;
    }

    &-inter {
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      background: #FF7A00;
      border-radius: 4px;
      padding: 0.5px 4px;
      color: #fff;
      margin-right: 16px;
    }

    &-shop {
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      color: #161616;
      border-radius: 4px;
      padding: 0.5px 4px;
      background: #F5F6FA;
    }

    p {
      height: 59px;
      overflow: hidden;
    }
  }
`
