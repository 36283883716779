export default [
  {
    image: require('../images/hub-know-more-auto.png'),
    title: 'Consórcio Auto e Moto',
    description: 'Parcelas que cabem no bolso para acelerar seu sonho!',
    link: '/pra-voce/consorcio/auto-moto',
  },
  {
    image: require('../images/hub-know-more-immobile.png'),
    title: 'Consórcio Imobiliário',
    description: 'Aproveite cartas de crédito de R$ 139.000 a R$ 278.000. Não tem juros e nem entrada.',
    link: '/pra-voce/consorcio/imobiliario/',
  },
]
